<script setup lang="ts">
const props = defineProps<{
  typingQuery: string;
  displayTotal: number;
}>();
const { typingQuery } = toRefs(props);

defineEmits<{
  "link-clicked": [];
}>();

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const { searchTerm, search, getProducts, getTotal, loading } =
  useProductSearchSuggest();
const { getDisplayVariant } = useProductHelper();

watch(typingQuery, (value) => {
  if (value.length >= 3) {
    performSuggestSearch(value);
  }
});

// Defer the search request to prevent the search from being triggered too after typing
const performSuggestSearch = useDebounceFn((value) => {
  searchTerm.value = value;
  search({
    filter: [
      { field: "customFields.inMainSearch", type: "equals", value: "true" },
    ],
    associations: {
      children: {
        associations: {
          cover: {},
          deliveryTime: {},
          seoUrls: {},
        },
      },
    },
  });
}, 300);

const searchProducts = computed(() => {
  return getProducts?.value?.slice(0, props.displayTotal);
});

const active = ref(true);
</script>

<template>
  <ScheppachSpinner v-if="loading" />
  <template v-else-if="getTotal > 0">
    <div>
      <div
        class="mb-4 uppercase c-scheppach-neutral-500 text-sm b-b b-scheppach-neutral-400"
      >
        {{ $t("product.label") }}
      </div>

      <div class="flex flex-col gap-2">
        <ProductSuggestSearch
          v-for="product in searchProducts"
          :key="product.id"
          :product="getDisplayVariant(product)"
          @click="[(active = false), $emit('link-clicked')]"
        />
      </div>

      <NuxtLink
        class="uppercase w-full font-700 py-3 bg-scheppach-shades-0 text-center rd b b-scheppach-primary-700 mt-4 block cursor-pointer"
        :to="formatLink({ path: `/search`, query: { search: typingQuery } })"
        @click="[(active = false), $emit('link-clicked')]"
      >
        {{ $t("search.allProducts") }}
      </NuxtLink>
    </div>

    <template v-if="false">
      <!-- TODO intractions and articles -->
      <div class="mt-5">
        <div
          class="b-b uppercase b-scheppach-neutral-400 c-scheppach-neutral-500 text-sm mb-4"
        >
          Anleitungen & Artikel
        </div>

        <div class="flex flex-col gap-4">
          <div></div>
        </div>

        <NuxtLink>Alle Anleitungen & Artikel anzeigen</NuxtLink>
      </div>

      <NuxtLink
        class="uppercase py-2 w-full text-scheppach-shades-0 bg-scheppach-primary-500 block text-center rd text-sm font-700 cursor-pointer"
        :to="formatLink({ path: `/search`, query: { search: typingQuery } })"
      >
        Alle Ergebnisse anzeigen
      </NuxtLink>
    </template>
  </template>
  <div v-else class="text-center">
    {{ $t("search.noResults") }}
  </div>
</template>
